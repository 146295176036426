class CategoryNav extends Component {
	constructor(el) {
		super(el);
		this.opener = this.$find('[data-nav-opener]');
		this.openedClass = 'is-opened';
		this.type = this.$el.dataset.type;
		this.addEventListeners();
		this.deviceOpenedCheck();
		this.windowWidth = window.innerWidth;
	}

	toggleNav(e) {
		e.preventDefault();
		this.$el.classList.toggle(this.openedClass);
	}

	openNav() {
		this.$el.classList.add(this.openedClass);
	}

	closeNav() {
		this.$el.classList.remove(this.openedClass);
	}
	
	deviceOpenedCheck() {
		if(this.type !== 'absolute') {
			if(window.innerWidth < 769) {
				if(window.innerWidth === this.windowWidth)
					return;

				this.closeNav();
				this.windowWidth = window.innerWidth;
			} else {
				this.openNav();
			}
		}
	}

	addEventListeners() {
		this.opener && this.opener.addEventListener('click', e => this.toggleNav(e));
		window.addEventListener('resize', () => this.deviceOpenedCheck());
	}
}